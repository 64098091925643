<template>
    <div>
        <a-modal width="800px" :maskClosable="false" title="工单归档" :visible="true" :confirm-loading="confirmLoading"
            @ok="handleOk" @cancel="handleCancel">
            <a-form-model ref="ruleForm" :rules="rules" :model="form">
                <h3  style="margin-top:0">归档信息</h3>
                <a-form-model-item ref="demandServerType" label="需求类别" required prop="demandServerType">
                    <a-select  :getPopupContainer="(triggerNode) => triggerNode.parentNode" v-model="form.demandServerType" placeholder="请选择需求类别">
                        <a-select-option :getPopupContainer="(triggerNode) => triggerNode.parentNode"
                            v-for="d in demandServiceTypeList" :key="d.demandServerType">
                            {{ d.demandServerTypeName }}
                        </a-select-option>
                    </a-select>
                </a-form-model-item>
                <a-form-model-item label="需求分类" prop="Classify">
                    <a-cascader style="width: 100%;" v-model="form.Classify"
                        :getPopupContainer="(triggerNode) => triggerNode.parentNode" :options="options"
                        :fieldNames="fieldNames" placeholder="请选择需求分类" @change="onChange" />
                </a-form-model-item>
                <a-form-model-item ref="isReasonableDemand" label="是否有效需求" required prop="isReasonableDemand">
                    <a-radio-group v-model="form.isReasonableDemand">
                        <a-radio value="1">
                            是
                        </a-radio>
                        <a-radio value="2">
                            否
                        </a-radio>
                    </a-radio-group>
                </a-form-model-item>
                <a-form-model-item ref="solutionInformation" label="实际解决情况" required prop="solutionInformation">
                    <a-select  :getPopupContainer="(triggerNode) => triggerNode.parentNode" v-model="form.solutionInformation" placeholder="请选择实际解决情况">
                        <a-select-option v-for="d in actualColumns" :key="d.key">
                            {{ d.name }}
                        </a-select-option>
                    </a-select>
                </a-form-model-item>
                <a-form-model-item v-if="form.solutionInformation == '3'" ref="unsolvableText" label="无法解决原因"
                    prop="unsolvableText">
                    <a-textarea v-model="form.unsolvableText" :maxLength="200" rows="3" placeholder="请填写无法解决原因" auto-size />
                    <div style="text-align: right;position: absolute;width: 100%;">
                        {{ form.unsolvableText.length }}/200
                    </div>
                </a-form-model-item>
            </a-form-model>
        </a-modal>
    </div>
</template>
<script>
import { requirementDemandServertype, getclassFiy, pigeonhole } from '@/api/demandPool';
export default {
    data() {
        return {
            visible: false,
            confirmLoading: false,
            options: [],
            fieldNames: { label: 'classifyName', value: 'classifyId', children: 'childList' },
            demandServiceTypeList: [],
            form: {
                secondsClassify: [],// 需求分类
                demandServerType: undefined,// 需求类别
                isReasonableDemand: '1',// 是否有效需求
                solutionInformation: '',// 实际解决情况
                unsolvableText: '',// 请填写无法解决原因
                firstsClassify: '',
                secondsClassify: '',
                thirdsClassify: '',
                Classify:undefined,
            },
            rules: {
                Classify: [{ required: true, message: '请选择需求分类', trigger: 'blur' },],
                demandServerType: [{ required: true, message: '请选择需求类别', trigger: 'blur' },],
                unsolvableText: [{ required: true, message: '请填写无法解决原因', trigger: 'blur' },],
                solutionInformation: [{ required: true, message: '请选择实际解决情况', trigger: 'blur' },],
            },
            actualColumns: [{ name: '实际解决', key: '1' }, { name: '及时回复、响应', key: '2' }, { name: '受限', key: '3' }],
        }
    },
    props: {
        id: {
            type: String,
            default: ''
        },
        show: {
            type: Boolean,
            default: false
        }
    },
    watch: {
        show(val) {
            this.visible = val;
        }
    },
    created() {
        this.getServertype()
        this.getclassFiy()
    },
    methods: {
        handleOk() {
            this.$refs.ruleForm.validate((valid) => {
                if (valid) {
                    pigeonhole({ ...this.form, workOrderId: this.id }).then(res => {
                        if (res.code == "200") {
                            this.$message.success('提交成功');
                            this.handleCancel()
                            this.$emit('resetSearchForm')
                        } else {
                            this.$message.error('提交成功');
                        }
                    })
                }
            });
        },
        handleCancel() {
            this.$emit('update:show', false)
            Object.assign(this.form, this.$options.data().form)
        },
        onChange(value) {
            switch (value.length) {
                case 1:
                    this.form.firstsClassify = value[0]
                    break;
                case 2:
                    this.form.firstsClassify = value[0]
                    this.form.secondsClassify = value[1]
                    break;
                case 3:
                    this.form.firstsClassify = value[0]
                    this.form.secondsClassify = value[1]
                    this.form.thirdsClassify = value[2]
                    break;

                default:
                    break;
            }
        },
        //需求服务类型
        async getServertype() {
            const res = await requirementDemandServertype();
            if (res.code == 200) {
                this.demandServiceTypeList = res.data;
            }
        },
        recursion(res) {
            for (let index = 0; index < res.length; index++) {
                const item = res[index];
                if (item.childList.length) {
                    this.recursion(item.childList)
                } else {
                    console.log(item);
                    item.childList = null
                }
            }
        },
        async getclassFiy() {
            const res = await getclassFiy();
            this.recursion(res.data)
            this.options = res.data;
        },
    }
}
</script>