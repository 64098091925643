<template>
    <div>
        <a-modal title="提交服务" :maskClosable="false" :visible="true" :confirm-loading="confirmLoading" @ok="handleOk"
            @cancel="handleCancel">
            <a-form-model :rules="rules" ref="ruleForm" :model="form">
                <a-form-model-item ref="serviceText" label="服务描述" prop="serviceText">
                    <a-textarea style="height: 100px;" v-model="form.serviceText" :maxLength="200" rows="3"
                        placeholder="请输入服务描述，不超过200个字" auto-size />
                    <div style="text-align: right;position: absolute;width: 100%;">
                        {{ form.serviceText.length }}/200
                    </div>
                </a-form-model-item>
                <a-form-model-item ref="fileUrlList" label="附件上传" prop="fileUrlList">
                    <a-upload :action="IMG_API + '/oss/files'" list-type="picture-card" :file-list="form.fileUrlList"
                        :beforeUpload="beforeUpload" @preview="handlePreview" :showUploadList="{ showPreviewIcon: true }"
                        @change="handleChangeImg">
                        <div v-if="imgFileList.length < 3">
                            <a-icon type="plus" />
                        </div>
                    </a-upload>
                    <span>
                        支持常用的图片格式与视频格式，附件不超过3个，单个文件大小不超过50M
                    </span>
                    <a-modal :maskStyle="{ padding: '10px' }" :visible="previewVisible" :footer="null"
                        @cancel="handleIMGCancel">
                        <img alt="example" style="width: 100%" :src="previewImage" />
                    </a-modal>
                </a-form-model-item>
            </a-form-model>
        </a-modal>
    </div>
</template>
<script>
import { cooperationSubmit, principalSubmit } from '@/api/demandPool';
import { IMG_API } from "@/config";
export default {
    data() {
        return {
            IMG_API,
            visible: false,
            confirmLoading: false,
            form: {
                serviceText: '',
                fileUrlList: []
            },
            rules: {
                serviceText: [{ required: true, message: '请填写服务描述', trigger: 'blur' },],
            },
            imgFileList: [],
            previewImage: '',
            previewVisible: false
        }
    },
    props: {
        show: {
            type: Boolean,
            default: false
        },
        id: {
            type: String,
            default: ''
        },
        // 6 协作人 15 负责人
        submitService: {
            type: [Number, String],
            default: ''
        },
    },
    watch: {
        show(val) {
            this.visible = val;
        }
    },
    methods: {
        handleOk() {
            this.$refs.ruleForm.validate((valid) => {
                if (valid) {
                    var ifStatus = true;
                    let fileList = [];
                    this.form.fileUrlList.forEach((item) => {
                        if (item.status === 'done') {
                            fileList.push(item.response.redirect_uri);
                        } else {
                            ifStatus = false
                        }
                    });
                    if(!ifStatus){
                        this.$message.error('文件上传中');
                        return false
                    }
                    this.confirmLoading = true
                    let form = this.form
                    form.fileUrlList = fileList
                    if (this.submitService == 6) {
                        cooperationSubmit({ ...form, workOrderId: this.id }).then(res => {
                            this.confirmLoading = false
                            if (res.code == "200") {
                                this.$message.success(res.msg);
                                this.$emit('resetSearchForm')
                                this.handleCancel()
                            } else {
                                this.$message.error(res.msg);
                            }
                        });
                    } else {
                        principalSubmit({ ...this.form, workOrderId: this.id }).then(res => {
                            this.confirmLoading = false
                            if (res.code == "200") {
                                this.$message.success(res.msg);
                                this.$emit('resetSearchForm')
                                this.handleCancel()
                            } else {
                                this.$message.error(res.msg);
                            }
                        });
                    }

                }
            });
        },
        handleCancel() {
            this.$emit('update:show', false)
            Object.assign(this.form, this.$options.data().form)
        },
        // 文件上传前判断类型
        beforeUpload(file) {
            console.log(file);
            let fileType =
                file.type == "video/mp4" ||
                file.type == "video/quicktime" ||
                file.type == "image/png" ||
                file.type == "image/jpg" ||
                file.type == "image/jpeg";
            if (!fileType) {
                this.$message.error("文件格式不正确");
                return null;
            } else {
                return true;
            }
        },
        // 预览图片
        handlePreview(file) {
            this.previewImage = file.url ? file.url : file.response.redirect_uri;
            this.previewVisible = true;
        },
        handleChangeImg(info) {
            this.form.fileUrlList = info.fileList
            this.imgFileList = info.fileList
        },
        handleIMGCancel() {
            this.previewVisible = false;
        },
    }
}
</script>