<template>
    <div>
        <a-modal title="拒收工单"  :maskClosable="false" :visible="true" :confirm-loading="confirmLoading" @ok="handleOk" @cancel="handleCancel">
            <a-form-model :rules="rules" ref="ruleForm" :model="form">
                <a-form-model-item ref="rejectionText" label="拒收理由 " prop="rejectionText">
                    <a-textarea style="height: 100px;" v-model="form.rejectionText" :maxLength="100" rows="3"
                        placeholder="请输入拒收理由，不超过100个字" auto-size />
                    <div style="text-align: right;position: absolute;width: 100%;">
                        {{ form.rejectionText.length }}/100
                    </div>
                </a-form-model-item>
            </a-form-model>
        </a-modal>
    </div>
</template>
<script>
import { rejectionOrder } from '@/api/demandPool';
export default {
    data() {
        return {
            visible: false,
            confirmLoading: false,
            form: {
                rejectionText: ''
            },
            rules: {
                rejectionText: [{ required: true, message: '请填写拒收原因', trigger: 'blur' },],
            },
        }
    },
    props: {
        show: {
            type: Boolean,
            default: false
        },
        id: {
            type: String,
            default: ''
        },
    },
    watch: {
        show(val) {
            this.visible = val;
        }
    },
    methods: {
        handleOk() {
            this.$refs.ruleForm.validate((valid) => {
                if (valid) {
                    rejectionOrder({ ...this.form, workOrderId: this.id }).then(res => {
                        if (res.code == "200") {
                            this.$message.success(res.msg);
                            this.$emit('resetSearchForm')
                           this.handleCancel()
                        } else {
                            this.$message.error(res.msg);
                        }
                    });
                } else {

                }
            });
        },
        handleCancel() {
            this.$emit('update:show', false)
            Object.assign(this.form, this.$options.data().form)
        }
    }
}
</script>