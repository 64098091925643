<template>
    <a-modal title="结束工单" :maskClosable="false" :visible="true" :confirm-loading="confirmLoading" @ok="handleOk"
        @cancel="handleCancel">
        <a-form-model ref="ruleForm" :model="form" :rules="rules">
            <h3 style="margin-top:0">归档信息</h3>
            <a-form-model-item ref="demandThawedState" label="需求解决状态" required prop="demandThawedState">
                <a-select :getPopupContainer="(triggerNode) => triggerNode.parentNode" v-model="form.demandThawedState"
                    placeholder="请选择实际解决情况" @change="needOnConfirm">
                    <a-select-option v-for="d in needColumns" :key="d.key">
                        {{ d.name }}
                    </a-select-option>
                </a-select>
            </a-form-model-item>
            <a-form-model-item v-if="form.demandThawedState == 1" ref="solutionTime" label="需求解决时间"
                prop="solutionTime">
                <!-- <a-date-picker :disabled-date="disabledDate" :disabled-time="disabledTime" v-model="form.solutionTime"
                    style="width: 100%;"  :show-time="{ format: 'HH:mm' }" placeholder="请选择需求解决时间" /> -->
                    <div style="display: flex;align-items: center;">
                        <span style="display: none;">{{ solutionTime }}</span>
                            <a-date-picker :disabled-date="disabledDate"
                             style="margin-right: 20px;"
                            :getCalendarContainer="(triggerNode) => triggerNode.parentNode" v-model="form.solutionDate"
                            format="YYYY-MM-DD" placeholder="请选择需求解决时间" />
                            <a-time-picker :disabled="disabledchoiceSolutionTime"
                             v-model="form.solutionH"
                             @openChange ="openChange"
                            :getPopupContainer="(triggerNode) => triggerNode.parentNode" format="HH:mm" />
                        </div>
                </a-form-model-item>
            <a-form-model-item v-if="form.demandThawedState" ref="resolvedReason" :label="wordTitle"
                prop="resolvedReason">
                <a-textarea :rows="4" :maxlength="200" placeholder="请输入服务描述" v-model="form.resolvedReason" />
                <div style="text-align: right;position: absolute;width: 100%;">
                    {{ form.resolvedReason.length }}/200
                </div>
            </a-form-model-item>
            <a-form-model-item v-if="form.demandThawedState == 1" ref="fileUrlList" label="附件上传" prop="fileUrlList">
                <a-upload :action="IMG_API + '/oss/files'" list-type="picture-card" :file-list="form.fileUrlList"
                    :beforeUpload="beforeUpload" @preview="handlePreview" :showUploadList="{ showPreviewIcon: true }"
                    @change="handleChangeImg">
                    <div v-if="imgFileList.length < 3">
                        <a-icon type="plus" />
                    </div>
                </a-upload>
                <span>
                    支持常用的图片格式与视频格式，附件不超过3个，单个文件大小不超过50M
                </span>
                <a-modal :maskStyle="{ padding: '10px' }" :visible="previewVisible" :footer="null"
                    @cancel="handleIMGCancel">
                    <img alt="example" style="width: 100%" :src="previewImage" />
                </a-modal>
            </a-form-model-item>
        </a-form-model>
    </a-modal>
</template>

<script>
import * as  api from '@/api/demandPool';
import { IMG_API } from "@/config";
import moment from 'moment';
export default {
    data() {
        return {
            IMG_API,
            visible: false,
            confirmLoading: false,
            needColumns: [
                { name: "已解决", key: "1" },
                { name: "无法解决", key: "2" },
                { name: "延期解决", key: "3" },
                { name: "关闭", key: "4" },
            ], //需求类型下拉值
            options: [],
            form: {
                demandThawedState: undefined,// 需求解决状态
                solutionTime: '',// 是否有效需求
                resolvedReason: '',// 服务描述
                fileUrlList: [],
                solutionDate:'',
                solutionH:'',
            },
            rules: {
                demandThawedState: [{ required: true, message: '请选择需求解决状态', trigger: 'blur' },],
                solutionTime: [{ required: true, message: '请选择需求解决时间', trigger:['blur','change'] },],
                resolvedReason: [{ required: true, message: '请填写', trigger: 'blur' },],
            },
            previewVisible: false,
            imgFileList: [],
            previewImage: '',
            wordTitle: '',
            actualColumns: [{ name: '实际解决', key: '1' }, { name: '及时回复、响应', key: '2' }, { name: '受限', key: '3' }],
        }
    },
    props: {
        id: {
            type: String,
            default: ''
        },
        show: {
            type: Boolean,
            default: false
        },
        startingTime: {
            type: String,
            default: ''
        }
    },
    watch: {
        show(val) {
            this.visible = val;
        }
    },
    computed:{
        solutionTime(){
            if (this.form.solutionDate && this.form.solutionH) {
                this.form.solutionTime = moment(this.form.solutionDate).format("YYYY-MM-DD") +" " + moment(this.form.solutionH).format("HH:mm")
                this.$refs.ruleForm.validateField('solutionTime')
                if(moment(this.form.solutionTime).isSame(this.startingTime)){
                       return
                } 
                if(moment(this.form.solutionTime).isAfter(this.startingTime)){
                    console.log('结束时间大于开始时间');
                }else{
                    this.$message.error('您填写的需求解决时间早于需求提出时间，请确认后重新输入')
                    this.form.solutionTime = ''
                    this.form.solutionH = ""
                }
                if(moment(this.form.solutionTime).isAfter(moment())){
                    this.$message.error('您填写的需求解决时间晚于当前时间，请确认后重新输入')
                    this.form.solutionTime = ''
                    this.form.solutionH = ""
                }

            }else{
                this.form.solutionTime = ""
            }
        },
         // 解决时间是否可选择
         disabledchoiceSolutionTime(){
            if(this.form.solutionDate){
                return false
            }else{
                this.form.solutionH = ""
                return true
            }
        },
    },
    methods: {
        moment,
        disabledDate(current) {
            // 获取当前日期
            return current > moment().endOf('day') || current < moment(this.startingTime ? moment(this.startingTime).format("YYYY-MM-DD") : '');
        },

        disabledTime(current) {
            // 获取当前时间 
            const currentTime = moment();
            let str = this.startingTime
            // moment(text).format("YYYY-MM-DD HH:mm:ss")
            if (!this.form.solutionTime) {
                if(moment(str).format("YYYY-MM-DD") == currentTime.format("YYYY-MM-DD")){
                    return {
                    disabledHours: () => {
                        let a = this.range(0, 24).splice(0, moment(str).hour())
                        let b = this.range(0, 24).splice(currentTime.hour() + 1)
                        return [...a, ...b]
                    },
                    disabledMinutes: () => {
                        if ( currentTime.hour() == moment(str).hour()) {
                            let a = this.range(0, 60).splice(0,moment(str).minute())
                            let b = this.range(0, 60).splice(currentTime.minute() + 1)
                            return [...a, ...b]
                        } else {
                            console.log('显示完整时就按')
                            return this.range(0, 60).splice(currentTime.minute() + 1)
                        }
                    },
                }
                }
                return {
                    disabledHours: () => this.range(0, 24).splice(currentTime.hour() + 1),
                    disabledMinutes: () => this.range(0, 60).splice(currentTime.minute() + 1),
                    disabledSeconds: () => this.range(0, 60).splice(currentTime.second() + 1),
                };
            }
            if (this.form.solutionTime.format("YYYY-MM-DD") === moment(str).format("YYYY-MM-DD") && moment(str).format("YYYY-MM-DD") == currentTime.format("YYYY-MM-DD")) {
                return {
                    disabledHours: () => {
                        let a = this.range(0, 24).splice(0, moment(str).hour())
                        let b = this.range(0, 24).splice(currentTime.hour() + 1)
                        return [...a, ...b]
                    },
                    disabledMinutes: () => {
                        console.log(this.form.solutionTime.hour(), currentTime.hour(), moment(str).hour())
                        if (this.form.solutionTime.hour() == currentTime.hour() && this.form.solutionTime.hour() == moment(str).hour()) {
                            console.log('开始时间 等于结束时间,结束时间等于当前时间=========》', moment(str).hour());
                            let a = this.range(0, 60).splice(currentTime.minute() + 1)
                            let b = this.range(0, 60).splice(currentTime.minute() + 1)
                            return [...a, ...b]
                        } else if (this.form.solutionTime.hour() == moment(str).hour() && this.form.solutionTime.hour() != currentTime.hour()) {
                            console.log('开始时间 等于结束时间,结束时间不等于当前时间=========》', moment(str).hour());
                            let a = this.range(0, 60).splice(0, moment(str).minute())
                            return a
                        } else if (this.form.solutionTime.hour() != moment(str).hour() && this.form.solutionTime.hour() == currentTime.hour()) {
                            console.log('开始时间 不等于结束时间,结束时间等于当前时间=========》', moment(str).hour());
                            return this.range(0, 60).splice(currentTime.minute() + 1)
                        } else {
                            console.log('显示完整时就按')
                            return []
                        }

                    },
                }
            } else if (this.form.solutionTime.format("YYYY-MM-DD") === moment(str).format("YYYY-MM-DD") && moment(str).format("YYYY-MM-DD") !== currentTime.format("YYYY-MM-DD")) {
                return {
                    disabledHours: () => {
                        let a = this.range(0, 24).splice(0, moment(str).hour())
                        return a
                    },
                    disabledMinutes: () => {
                        if(this.form.solutionTime.hour() == currentTime.hour() && this.form.solutionTime.hour() == moment(str).hour()){
                            let a = this.range(0, 60).splice(0, moment(str).minute())
                            let b = this.range(0, 60).splice(currentTime.minute() + 1)
                            return [...a,...b]
                        }else if(this.form.solutionTime.hour() == moment(str).hour() &&  this.form.solutionTime.hour()!== currentTime.hour()){
                           return this.range(0, 60).splice(0, moment(str).minute())
                        } else {
                            return []
                        }
                    },
                }
            }
            else if (this.form.solutionTime.format("YYYY-MM-DD") !== currentTime.format("YYYY-MM-DD")) {

            }
            else {
                return {
                    disabledHours: () => this.range(0, 24).splice(currentTime.hour() + 1),
                    disabledMinutes: () => this.range(0, 60).splice(currentTime.minute() + 1),
                    disabledSeconds: () => this.range(0, 60).splice(currentTime.second() + 1),
                };
            }
            // if(this.form.solutionTime ==moment(str))
            // 获取当前日期
            // 如果选择的日期是当前日期，则将当前时间设置为禁用的最大时间
            // if (current.isSame(moment().startOf('day'), 'day')) {
            // }
        },
        range(start, end) {
            const result = [];
            for (let i = start; i < end; i++) {
                result.push(i);
            }
            return result;
        },
        handleOk() {
            this.$refs.ruleForm.validate(async (valid) => {
                if (valid) {
                    let fileList = [];
                    console.log(this.form.fileUrlList);
                    this.form.fileUrlList.forEach((item) => {
                        if (item.status === 'done') {
                            fileList.push(item.response.redirect_uri);
                        } else {
                        }
                    });
                    let obj = {
                        workOrderId: this.id,
                        demandThawedState: this.form.demandThawedState,
                        resolvedReason: this.form.resolvedReason,
                        fileUrlList: fileList,
                        solutionTime: this.form.solutionTime ? this.form.solutionTime : '',
                    };
                    if(obj.demandThawedState==1){
                      obj.serverDescribe = this.form.resolvedReason;
                    }
                    const res = await api.oneselfFinishWorkorder(obj);
                    if (res.code == "200") {
                        this.$message.success('提交成功');
                        this.$emit('resetSearchForm')
                        this.handleCancel()
                        this.$emit('resetSearchForm')
                    } else {
                        this.$message.error(res.msg);
                    }

                }
            });
        },
        handleCancel() {
            this.$emit('update:show', false)
            Object.assign(this.form, this.$options.data().form)
        },
        // 文件上传前判断类型
        beforeUpload(file) {
            console.log(file);
            let fileType =
                file.type == "video/mp4" ||
                file.type == "video/quicktime" ||
                file.type == "image/png" ||
                file.type == "image/jpg" ||
                file.type == "image/jpeg";
            if (!fileType) {
                this.$message.error("文件格式不正确");
                return null;
            } else {
                return true;
            }
        },
        // 预览图片
        handlePreview(file) {
            this.previewImage = file.url ? file.url : file.response.redirect_uri;
            this.previewVisible = true;
        },
        handleChangeImg(info) {
            this.form.fileUrlList = info.fileList
            this.imgFileList = info.fileList
            console.log(this.form.fileUrlList);
        },
        handleIMGCancel() {
            this.previewVisible = false;
        },
        //需求类型
        needOnConfirm(value) {
            if (value == "1") {
                this.wordTitle = "服务信息";
            }
            if (value == "2") {
                this.wordTitle = "无法解决原因";
            }
            if (value == "3") {
                this.wordTitle = "延期解决原因";
            }
            if (value == "4") {
                this.wordTitle = "关闭原因";
            }
            this.form.resolvedReason = ''
            this.$refs.ruleForm.clearValidate()
        },
        openChange(){
            if(!this.form.solutionDate){
                this.form.solutionH =''
            }
        },
    }
}
</script>

<style scoped>
.ant-form-item {
    margin-bottom: 0;
}

h3 {
    margin-top: 20px;
}
</style>
