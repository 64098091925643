<template>
    <div>
        <a-modal :maskClosable="false" title="完成回访" :visible="true" :confirm-loading="confirmLoading" @ok="handleOk"
            @cancel="handleCancel">
            <a-form-model ref="ruleForm" :model="form">
                <h3  style="margin-top:0">回访信息 </h3>
                <a-form-model-item ref="isResolved" label="需求是否解决" required prop="isResolved">
                    <a-radio-group v-model="form.isResolved">
                        <a-radio :value="1">
                            是
                        </a-radio>
                        <a-radio :value="0">
                            否
                        </a-radio>
                    </a-radio-group>
                </a-form-model-item>
                <a-form-model-item ref="isSatisfaction"
                    label="客户是否满意                                                                                                 "
                    required prop="isSatisfaction">
                    <a-radio-group v-model="form.isSatisfaction">
                        <a-radio value="1">
                            是
                        </a-radio>
                        <a-radio value="3">
                            否
                        </a-radio>
                    </a-radio-group>
                </a-form-model-item>
                <a-form-model-item ref="returnVisitText" label="其他反馈" prop="returnVisitText">
                    <a-textarea style="height: 100px;" v-model="form.returnVisitText" :maxLength="100" rows="3"
                        placeholder="如您有其他反馈，请在此处填写" auto-size />
                    <div style="text-align: right;position: absolute;width: 100%;">
                        {{ form.returnVisitText.length }}/100
                    </div>

                </a-form-model-item>
            </a-form-model>
        </a-modal>
    </div>
</template>
<script>
import * as  api from '@/api/demandPool';
export default {
    data() {
        return {
            visible: false,
            confirmLoading: false,
            form: {
                returnVisitType: "2",
                isSatisfaction: "1",
                isResolved: 1,
                returnVisitText: "",
            }
        }
    },
    props: {
        show: {
            type: Boolean,
            default: false
        },
        id: {
            type: String,
            default: ''
        },
        typeState: {
            type: Boolean,
            default: false
        }
    },
    watch: {
        show(val) {
            this.visible = val;
        }
    },
    methods: {
        handleOk() {
            this.$refs.ruleForm.validate((valid) => {
                if (valid) {
                    api.returnVisittype({ ...this.form, workOrderId: this.id, }).then(res => {
                        if (res.code == "200") {
                            this.$message.success('提交成功');
                            this.$emit('resetSearchForm')
                            this.handleCancel()
                        } else {
                            this.$message.error(res.msg);
                        }
                    })
                }
            });
        },
        handleCancel() {
            this.$emit('update:show', false)
            Object.assign(this.form, this.$options.data().form)
        }
    }
}
</script>