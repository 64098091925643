<template>
    <div>
        <a-modal width="800px" :maskClosable="false" title="创建工单" :visible="true" :confirm-loading="confirmLoading"
            @ok="handleOk" @cancel="showConfirm" :bodyStyle="{ height: '75vh', overflowY: 'scroll', padding: '20px 120px ' }">
            <a-form-model ref="ruleForm" :model="form" :rules="rules">
                <h3 style="margin-top:0">工单内容</h3>
                <a-form-model-item ref="demandType" label="需求类型" required prop="demandType">
                    <a-select :getPopupContainer="(triggerNode) => triggerNode.parentNode" v-model="form.demandType"
                        placeholder="请选择需求服务类型" @change="handleDemandTypeChange">
                        <a-select-option v-for="d in demandServiceTypeList" :key="d.demandType">
                            {{ d.demandTypeName }}
                        </a-select-option>
                    </a-select>
                </a-form-model-item>
                <a-form-model-item v-if="form.demandType == '9' ||
                    form.demandType == '10' ||
                    form.demandType == '11'" ref="enterpriseProjectId" label="所在项目" required
                    prop="enterpriseProjectId">
                    <a-select @change="changeProject" :getPopupContainer="(triggerNode) => triggerNode.parentNode"
                        v-model="form.enterpriseProjectId" placeholder="请选择所属项目">
                        <a-select-option v-for="item in projectMsgList" :key="item.projectId" :value="item.projectId">
                            {{ item.projectName }}
                        </a-select-option>
                    </a-select>
                </a-form-model-item>
                <h3>需求信息</h3>
                <a-form-model-item ref="startingTime" label="需求提出时间" prop="startingTime">
                    <a-date-picker :getPopupContainer="(triggerNode) => triggerNode.parentNode"
                        :disabled-date="disabledDate" :disabled-time="disabledTime" v-model="form.startingTime"
                        style="width: 100%;"  :show-time="{ format: 'HH:mm' }" placeholder="请选择需求提出时间" format="YYYY-MM-DD HH:mm" />
                </a-form-model-item>
                <a-form-model-item ref="demandDescribe" label="需求描述" prop="demandDescribe">
                    <a-textarea v-model="form.demandDescribe" style="height: 80px;" :maxLength="100" rows="3"
                        placeholder="请输入需求描述，不超过100个字" auto-size />
                    <div style="text-align: right;position: absolute;width: 100%">
                        {{ form.demandDescribe.length }}/100
                    </div>
                </a-form-model-item>
                <a-form-model-item ref="desiredSolution" label="期望解决方式" prop="desiredSolution">
                    <a-textarea style="height: 80px;" v-model="form.desiredSolution" :maxLength="100" rows="3"
                        placeholder="请输入期望解决方式，不超过100个字" auto-size />
                    <div style="text-align: right;position: absolute;width: 100%">
                        {{ form.desiredSolution.length }}/100
                    </div>
                </a-form-model-item>
                <a-form-model-item ref="fileUrlList" label="附件上传" prop="fileUrlList">
                    <a-upload :action="IMG_API + '/oss/files'" list-type="picture-card" :file-list="form.fileUrlList"
                        :beforeUpload="beforeUpload" @preview="handlePreview" :showUploadList="{ showPreviewIcon: true }"
                        @change="handleChangeImg">
                        <div v-if="imgFileList.length < 3">
                            <a-icon type="plus" />
                        </div>
                    </a-upload>
                    <span>
                        支持常用的图片格式与视频格式，附件不超过3个，单个文件大小不超过50M
                    </span>
                    <a-modal :maskStyle="{ padding: '10px' }" :visible="previewVisible" :footer="null"
                        @cancel="handleIMGCancel">
                        <img alt="example" style="width: 100%" :src="previewImage" />
                    </a-modal>
                </a-form-model-item>
                <div v-if="form.customerViewShow == '1'">
                    <h3>客户信息</h3>
                    <a-form-model-item ref="enterpriseProjectId" label="所在项目" required prop="enterpriseProjectId">
                        <a-select @change="changeProject" :getPopupContainer="(triggerNode) => triggerNode.parentNode"
                            v-model="form.enterpriseProjectId" placeholder="请选择所属项目">
                            <a-select-option v-for="item in projectMsgList" :key="item.projectId" :value="item.projectId">
                                {{ item.projectName }}
                            </a-select-option>
                        </a-select>
                    </a-form-model-item>
                    <a-form-model-item v-if="form.enterpriseProjectId" required ref="enterpriseId" label="企业名称"
                        prop="enterpriseId">
                        <a-select :getPopupContainer="(triggerNode) => triggerNode.parentNode" show-search
                            v-model="form.enterpriseId" placeholder="请输入企业名称以选择" :default-active-first-option="false"
                            :show-arrow="false" :filter-option="false" :not-found-content="null" @search="getEnterpriseList"
                            @change="changeEnterprise">
                            <a-select-option v-for="d in enterpriseList" :key="d.enterpriseId">
                                {{ d.enterpriseName }}
                            </a-select-option>
                        </a-select>
                    </a-form-model-item>
                    <a-form-model-item v-if="form.enterpriseId" ref="linkmanName" label="客户姓名" prop="linkmanName">
                        <a-select mode="tags" show-search v-model="form.linkmanName" placeholder="请输入客户姓名以选择"
                            :default-active-first-option="false" :show-arrow="false" :filter-option="false"
                            :not-found-content="null" :getPopupContainer="(triggerNode) => triggerNode.parentNode"
                            @search="onSearchName" @change="Changename">
                            <a-select-option v-for="d in persionList" :key="d.id">
                                {{ d.name }}
                            </a-select-option>
                        </a-select>
                    </a-form-model-item>
                    <a-form-model-item v-if="form.enterpriseId" ref="linkmanPhone" label="客户联系方式" prop="linkmanPhone">
                        <a-input placeholder="请输入联系方式" v-model="form.linkmanPhone" :maxLength="11" />
                    </a-form-model-item>
                    <a-form-model-item ref="linkmanDuty" label="客户职务" required prop="linkmanDuty">
                        <a-select :getPopupContainer="(triggerNode) => triggerNode.parentNode" v-model="form.linkmanDuty"
                            placeholder="请选择客户职务" @change="changeDuty">
                            <a-select-option v-for="d in postColumns" :key="d.linkmanDuty">
                                {{ d.linkmanDutyName }}
                            </a-select-option>
                        </a-select>
                    </a-form-model-item>
                </div>
                <h3>工单分派</h3>
                <a-form-model-item ref="waysOfResolution" label="解决方式" required prop="waysOfResolution">
                    <a-radio-group v-model="form.waysOfResolution">
                        <a-radio value="1">
                            自行解决
                        </a-radio>
                        <a-radio value="2">
                            分派解决
                        </a-radio>
                    </a-radio-group>
                </a-form-model-item>
                <a-form-model-item v-if="form.waysOfResolution == '2'" ref="dispatchMode" label="分派方式" required
                    prop="dispatchMode">
                    <a-radio-group v-model="form.dispatchMode">
                        <a-radio value="1">
                            自行分派
                        </a-radio>
                        <a-radio value="2">
                            委托分派
                        </a-radio>
                    </a-radio-group>
                </a-form-model-item>
                <a-form-model-item v-if="form.waysOfResolution == '2' && form.dispatchMode == 1" required ref="principalId"
                    label="服务负责人" prop="principalId">
                    <a-tree-select :replaceFields="{
                        title: 'organizationName',
                        key: 'organizationId',
                        children: 'childrenList',
                        value: 'organizationId'
                    }" :filterTreeNode="false" show-search :dropdown-style="{ maxHeight: '300px', overflow: 'auto' }"
                        v-model="form.principalId" :maxTagCount="1" placeholder="请输入负责人姓名或手机号以选择"
                        :getPopupContainer="(triggerNode) => triggerNode.parentNode" :tree-data="treeData"
                        @search="onSearchUserName"  @change='changePrincipalId'>
                    </a-tree-select>
                </a-form-model-item>
            </a-form-model>
            <a-modal title="保存草稿" :visible="showVisble" @cancel="showVisble = false">
                <p>确定要保存为草稿吗？</p>
                <template slot="footer">
                    <a-button key="back" @click="handleCancelText">
                        取消
                    </a-button>
                    <a-button key="submit" type="primary" @click="handleOkText">
                        确定
                    </a-button>
                </template>
            </a-modal>
        </a-modal>
    </div>
</template>
<script>
import moment from 'moment';
import * as  api from '@/api/demandPool';
import { IMG_API } from "@/config";
export default {
    props: {
        show: {
            type: Boolean,
            default: false
        }
    },
    watch: {
        show(val) {
            this.visible = val;
        }
    },
    data() {
        return {
            IMG_API,
            visible: false,
            confirmLoading: false,
            form: {
                fileUrlList: [],
                demandType: undefined, //需求服务类型
                demandDescribe: "", //需求描述
                desiredSolution: "", //期望解决方式
                enterpriseId: undefined, //企业
                enterpriseProjectId: undefined, //项目
                principalId: undefined, //服务负责人
                linkmanName: undefined, //联系人
                linkmanPhone: "", //手机号
                waysOfResolution: "1", //解决方式
                dispatchMode: "1", //分派方式
                linkmanDuty: undefined, //联系人职务
                startingTime: "", //需求发起时间
                projectMsgList: "",
                customerViewShow: false,//客户卡片显隐
                demandTypeText: '',// app需求类型回显
                enterpriseIdList: '',// app所在企业回显
                enterpriseProjectIdList: '',// app所在项目回显
                principalIdData: "", //服务负责人（回显）
                linkmanDutyName: "",// 职务回显
            },
            userList: [],// 服务负责人列表
            isRequired: '',// 客户信息显示
            imgFileList: [],
            enterpriseList: [],// 企业列表
            projectMsgList: [],
            persionList: [],//客户列表
            needColumns: [], //需求类型下拉值
            postColumns: [], //需求类型下拉值
            previewImage: '',// 预览图片
            previewVisible: false,
            demandServiceTypeList: [],
            rules: {
                startingTime: [{ required: true, message: '请选择需求提出时间', trigger: ['blur','change'] },],
                linkmanDuty: [{ required: true, message: '请选择客户职务', trigger: 'blur' },],
                demandType: [{ required: true, message: '请选择需求类型', trigger: 'blur' },],
                demandDescribe: [{ required: true, message: '请填写需求描述', trigger: 'blur' },],
                linkmanPhone: [{ required: true, message: '请填写客户联系方式', trigger: 'blur' },],
                linkmanName: [{ required: true, message: '请填写客户姓名', trigger: 'blur' },],
                enterpriseProjectId: [{ required: true, message: '请填写客户所在项目', trigger: 'blur' },],
                principalId: [{ required: true, message: '请选择服务负责人', trigger: 'blur' },],
            },
            treeData: [],
            showVisble: false
        };
    },
    created() {
        this.innerdemandType()
        this.getProject()
        this.getJobList()
        this.onSearchUserName()
        api.getWorkOrderDraft({ source: 'web',projectId:''  }).then(res => {
            if (res.data) {
                this.form = JSON.parse(res.data)
                if (this.form.enterpriseProjectId) {
                    this.getEnterpriseList()
                }
            }
            console.log(JSON.parse(res.data));
        }).catch(err => {
            console.log(err, '草稿');
        })
    },
    methods: {
        handleCancelText() {
            api.removeWorkOrderDraft({ source: 'web',projectId:'' }).then(res => {

            })
            this.$emit('resetSearchForm')
            this.handleCancel()
        },
        handleOkText() {
            console.log(this.form, '====================');
            let startingTime = this.form.startingTime ? moment(this.form.startingTime).format("YYYY-MM-DD HH:mm") : '';
            let fileUrlList = []
            this.form.fileUrlList.forEach((item) => {
                if (item.status === 'done') {
                    fileUrlList.push({
                        url: item.url || item.response.redirect_uri,
                        file: { type: item.type },
                        uid: item.uid,
                        name: item.name,
                        status: 'done',
                    });
                } else {
                }
            });
            let formData = {};
            api.saveWorkOrderDraft({ ...this.form, fileUrlList, startingTime, source: 'web' ,projectId:''}).then((res) => {
                if (res.code == 200) {
                    this.showVisble = false;
                    this.handleCancel()
                    this.$emit('resetSearchForm')
                }
            });
        },
        showConfirm() {

            if (this.form.demandTypeText || this.form.startingTime || (this.form.demandType == '9' ||
                this.form.demandType == '10' ||
                this.form.demandType == '11' && this.form.enterpriseProjectIdList) || this.form.demandDescribe) {
                this.showVisble = true
            } else {
                this.handleCancel()
            }


        },
        //需求服务类型
        async innerdemandType() {
            const res = await api.innerdemandType();
            if (res.code == 200) {
                this.demandServiceTypeList = res.data;
            }
        },
        //获取项目信息
        async getProject() {
            const res = await api.requirementProject3();
            if (res.code == 200) {
                console.log(res, '===============');
                this.projectMsgList = res.data;
            }
            console.log(this.projectMsgList, '====================');
        },
        // 限制时间
        disabledDate(current) {
            // 获取当前日期
            return current > moment().endOf('day')
        },
        disabledTime(current) {
            // 获取当前时间 
            const currentTime = moment();
            if (!this.form.startingTime) {
                return {
                    disabledHours: () => this.range(0, 24).splice(currentTime.hour() + 1),
                    disabledMinutes: () => this.range(0, 60).splice(currentTime.minute() + 1),
                }
            } else if (this.form.startingTime.format("YYYY-MM-DD") === currentTime.format("YYYY-MM-DD")) {
                if (this.form.startingTime.hour() == currentTime.hour()) {
                    return {
                        disabledHours: () => this.range(0, 24).splice(currentTime.hour() + 1),
                        disabledMinutes: () => this.range(0, 60).splice(currentTime.minute() + 1),
                    }
                } else {
                    return {
                        disabledHours: () => this.range(0, 24).splice(currentTime.hour() + 1),
                    }
                }

            } else {

            }

        },
        range(start, end) {
            const result = [];
            for (let i = start; i < end; i++) {
                result.push(i);
            }
            return result;
        },
        //判断客户信息显示隐藏
        handleDemandTypeChange(val) {
            let demandType =  this.form.demandType
            this.$refs.ruleForm.resetFields()
            this.form.demandType = demandType
            const selectedOption = this.demandServiceTypeList.find(option => option.demandType === val);
            this.isRequired = selectedOption.isRequired;
            console.log(selectedOption, '====================');
            this.form.customerViewShow = selectedOption.isRequired;
            this.form.demandTypeText = selectedOption.demandTypeName;
        },
        // 搜素客户
        async onSearchName(value) {
            this.checkedValue = "";
            const res = await api.customerFuzzyretrieval({
                enterpriseId: this.form.enterpriseId,
                staffName: value,
                page: 1,
                size: 100
            });
            if (res.code == "200") {
                this.persionList = res.data.staffList;
            }
        },
        // 选择客户
        // Changename(value) {
        //     const selectedOption = this.persionList.find(option => option.id === value);
        //     this.form.linkmanPhone = selectedOption.phoneNumber// 联系方式
        // },
        Changename(value) {
            console.log(value, '==========');
            const selectedOption = this.persionList.find(option => option.id === value[value.length - 1]);
            // this.form.linkmanPhone = selectedOption.phoneNumber// 联系方式
            console.log(selectedOption);
            this.form.linkmanPhone = selectedOption?.phoneNumber;//APP绑定手机号
            this.form.linkmanName = selectedOption ? selectedOption.name : value;//APP绑定手机号

            if (Array.isArray(this.form.linkmanName)) {
                this.form.linkmanName = this.form.linkmanName[this.form.linkmanName.length - 1]
                // 是数组
            }
            console.log(this.form.linkmanName);
        },
        async getJobList() {
            const res = await api.linkmanDuty();
            if (res.code == "200") {
                this.postColumns = res.data;
            }
        },
        // 预览图片
        handlePreview(file) {
            this.previewImage = file.url ? file.url : file.response.redirect_uri;
            this.previewVisible = true;
        },
        handleChangeImg(info) {
            this.form.fileUrlList = info.fileList
            this.imgFileList = info.fileList
        },
        // 文件上传前判断类型
        beforeUpload(file) {
            console.log(file);
            let fileType =
                file.type == "video/mp4" ||
                file.type == "video/quicktime" ||
                file.type == "image/png" ||
                file.type == "image/jpg" ||
                file.type == "image/jpeg";
            if (!fileType) {
                this.$message.error("文件格式不正确");
                return null;
            } else {
                return true;
            }
        },
        async handleOk(values) {
            this.$refs.ruleForm.validate(async (valid) => {
                if (valid) {
                    var ifStatus = true;
                    let fileList = [];
                    let formData = {};
                    this.form.fileUrlList.forEach((item) => {
                        if (item.status === 'done') {
                            fileList.push(item.url || item.response.redirect_uri);
                        } else {
                        }
                    });
                    if (!ifStatus) {
                        this.$message.error("文件上传中");
                        return false;
                    }

                    formData.fileUrlList = fileList;
                    formData.demandType = this.form.demandType;
                    formData.startingTime = this.form.startingTime ? moment(this.form.startingTime).format("YYYY-MM-DD HH:mm") : '';
                    formData.projectId = this.form.projectId;
                    formData.demandDescribe = this.form.demandDescribe;
                    formData.desiredSolution = this.form.desiredSolution;
                    formData.waysOfResolution = this.form.waysOfResolution;
                    formData.enterpriseProjectId = this.form.enterpriseProjectId;
                    if (this.form.waysOfResolution == "2") {
                        formData.dispatchMode = this.form.dispatchMode;
                        if(formData.dispatchMode == "1"){
                            formData.principalId = this.form.principalId;
                        } 
                    }
                    if (this.form.customerViewShow == "1") {
                        formData.linkmanName = this.form.linkmanName;
                        formData.linkmanPhone = this.form.linkmanPhone;
                        formData.linkmanDuty = this.form.linkmanDuty;
                        formData.enterpriseId = this.form.enterpriseId;
                    }
                    this.confirmLoading = true;
                    const res = await api.innerWorkorder(formData);
                    this.confirmLoading = false;
                    if (res.code == "200") {
                        this.$message.success("创建成功");
                        this.handleCancel()
                        api.removeWorkOrderDraft({ source: 'web',projectId:'' }).then(res => {})
                        this.$emit('resetSearchForm')
                    } else {
                        this.$message.error(res.msg);
                    }
                }
            })

        },
        seta(aa) {
            for (let item = 0; item < aa.length; item++) {
                if (aa[item].childrenList && aa[item].childrenList.length > 0 && aa[item].demandUserList && !aa[item].demandUserList.length) {
                    this.seta(aa[item].childrenList);
                    aa[item].selectable = false
                } else if (aa[item].childrenList && aa[item].childrenList.length > 0 && aa[item].demandUserList && aa[item].demandUserList.length) {
                    console.log(aa[item]);
                    aa[item].selectable = false
                    if (aa[item].childrenList) {
                            this.seta(aa[item].childrenList);
                        }
                    for (let itt = 0; itt < aa[item].demandUserList.length; itt++) {
                        let a = aa[item].demandUserList[itt]
                        aa[item].childrenList.push({
                            organizationId: a.userId,
                            organizationName: a.userName,
                        })
                    };
                } else {
                    if (aa[item].demandUserList && aa[item].demandUserList.length) {
                        aa[item].selectable = false
                        for (let itt = 0; itt < aa[item].demandUserList.length; itt++) {
                            let a = aa[item].demandUserList[itt]
                            aa[item].childrenList.push({
                                organizationId: a.userId,
                                organizationName: a.userName,
                            })
                        }
                    }

                }
            }
        },

        async onSearchUserName(val) {
            let res = await api.getDemandUserTree({
                roleId: 3,
                workOrderId: this.id,
                phoneNumberOrName: val
            })
            this.seta(res.data)
            console.log('res.data', res.data)
            this.treeData = res.data
        },
        handleCancel(e) {
            this.$emit('update:show', false)
            Object.assign(this.form, this.$options.data().form)
        },
        handleIMGCancel() {
            this.previewVisible = false;
        },
        async getEnterpriseList(value) {
            console.log(value);
            const res = await api.fuzzyretrieval({
                projectId: this.form.enterpriseProjectId,
                enterpriseName: value || '',
                page: 1,
                size: 100
            });
            if (res.code == "200") {
                this.enterpriseList = res.data.enterpriseList;
            } else {
                this.$message.error(res.msg);
            }
        },
        getLinkmanNameList() {

        },
        changeEnterprise(value, option) {
            const selectedOption = this.enterpriseList.find(option => option.enterpriseId === value);
            console.log(selectedOption);
            this.form.enterpriseIdList = selectedOption.enterpriseName
        },
        changeProject(value, option) {
            const selectedOption = this.projectMsgList.find(option => option.projectId === value);
            console.log(selectedOption);
            this.form.enterpriseProjectIdList = selectedOption.projectName
        },
        changeDuty(value) {
            console.log(value);
            const selectedOption = this.postColumns.find(option => option.linkmanDuty === value);
            console.log(selectedOption);
            this.form.linkmanDutyName = selectedOption.linkmanDutyName
        },
        changePrincipalId(value, label, extra) {
            this.form.principalIdData = label[0]
            console.log(this.form.principalIdData);
        },
    },
};
</script>
<style scoped>
.ant-form-item {
    margin-bottom: 0;
}

h3 {
    margin-top: 20px;
}
</style>
  