<template>
    <div>
        <a-modal width="800px" :maskClosable="false" title="结束工单" :visible="true" :confirm-loading="confirmLoading"
            @ok="handleOk" @cancel="handleCancel"
            :bodyStyle="{ height: '75vh', overflowY: 'scroll', padding: '20px 120px ' }">
            <a-form-model ref="ruleForm" :rules="rules" :model="form">
                <h3 style="margin-top:0">服务内容</h3>
                <a-form-model-item ref="solutionTime" label="需求解决时间" prop="solutionTime">
                    <!-- <a-date-picker :getCalendarContainer="(triggerNode) => triggerNode.parentNode"
                        :disabled-date="disabledDate" :disabled-time="disabledTime" v-model="form.solutionTime"
                        style="width: 100%;"  :show-time="{ format: 'HH:mm' }" placeholder="请选择需求解决时间" format="YYYY-MM-DD HH:mm" /> -->
                        <div style="display: flex;align-items: center;">
                        <span style="display: none;">{{ solutionTime }}</span>
                            <a-date-picker :disabled-date="disabledDate"
                             style="margin-right: 20px;"
                            :getCalendarContainer="(triggerNode) => triggerNode.parentNode" v-model="form.solutionDate"
                            format="YYYY-MM-DD" placeholder="请选择需求解决时间" />
                            <a-time-picker :disabled="disabledchoiceSolutionTime"
                             v-model="form.solutionH"
                             @openChange ="openChange"
                            :getPopupContainer="(triggerNode) => triggerNode.parentNode" format="HH:mm" />
                        </div>
                    </a-form-model-item>
                <a-form-model-item ref="serverDescribe" label="服务描述" prop="serverDescribe">
                    <a-textarea v-model="form.serverDescribe" style="height: 80px;" :maxLength="200" rows="3"
                        placeholder="请输入服务描述，不超过200个字" auto-size />
                    <div style="text-align: right;position: absolute;width: 100%">
                        {{ form.serverDescribe.length }}/200
                    </div>
                </a-form-model-item>
                <h3>归档信息</h3>
                <a-form-model-item ref="demandServerType" label="需求服务类型" required prop="demandServerType">
                    <a-select :getPopupContainer="(triggerNode) => triggerNode.parentNode" v-model="form.demandServerType"
                        placeholder="请选择需求服务类型">
                        <a-select-option v-for="d in demandServiceTypeList" :key="d.demandServerType">
                            {{ d.demandServerTypeName }}
                        </a-select-option>
                    </a-select>
                </a-form-model-item>
                <a-form-model-item label="需求分类" required prop="Classify">
                    <a-cascader style="width: 100%;" v-model="form.Classify"
                        :getPopupContainer="(triggerNode) => triggerNode.parentNode" :options="options"
                        :fieldNames="fieldNames" placeholder="请选择需求分类" @change="onChange" />
                </a-form-model-item>
                <a-form-model-item ref="isReasonableDemand" label="是否有效需求" required prop="isReasonableDemand">
                    <a-radio-group v-model="form.isReasonableDemand">
                        <a-radio value="1">
                            是
                        </a-radio>
                        <a-radio value="0">
                            否
                        </a-radio>
                    </a-radio-group>
                </a-form-model-item>
                <a-form-model-item ref="solutionInformation" label="实际解决情况" required prop="solutionInformation">
                    <a-select :getPopupContainer="(triggerNode) => triggerNode.parentNode"
                        v-model="form.solutionInformation" placeholder="请选择实际解决情况">
                        <a-select-option v-for="d in actualColumns" :key="d.key">
                            {{ d.name }}
                        </a-select-option>
                    </a-select>
                </a-form-model-item>
                <a-form-model-item ref="isGivePoints" label="是否发放积分" required prop="isGivePoints">
                    <a-radio-group v-model="form.isGivePoints">
                        <a-radio :value="1">
                            是
                        </a-radio>
                        <a-radio :value="0">
                            否
                        </a-radio>
                    </a-radio-group>
                </a-form-model-item>
                <template v-if="form.isGivePoints == '1'">
                    <a-form-model-item ref="givePointsType" label="积分发放类型" required prop="givePointsType">
                        <a-radio-group v-model="form.givePointsType">
                            <a-radio value="1">
                                安抚积分
                            </a-radio>
                            <a-radio value="2">
                                奖励积分
                            </a-radio>
                        </a-radio-group>
                    </a-form-model-item>
                    <a-form-model-item ref="givePointsAmount" label="积分发放数量" required prop="givePointsAmount">
                        <a-input placeholder="请输入积分发放数量" :maxLength="12" v-model="form.givePointsAmount" />
                    </a-form-model-item>
                </template>

                <h3>回访信息</h3>
                <a-form-model-item ref="requireCallback" label="是否需要回访" required prop="requireCallback">
                    <a-radio-group v-model="form.requireCallback">
                        <a-radio value="1">
                            是
                        </a-radio>
                        <a-radio value="0">
                            否
                        </a-radio>
                    </a-radio-group>
                </a-form-model-item>
                <template v-if="form.requireCallback == '1'">
                    <a-form-model-item ref="isFinish" label="是否完成回访" required prop="isFinish">
                        <a-radio-group v-model="form.isFinish">
                            <a-radio value="1">
                                是
                            </a-radio>
                            <a-radio value="0">
                                否
                            </a-radio>
                        </a-radio-group>
                    </a-form-model-item>
                    <a-form-model-item v-if="form.isFinish == '1'" ref="isResolved" label="需求是否解决" required
                        prop="isResolved">
                        <a-radio-group v-model="form.isResolved">
                            <a-radio value="1">
                                是
                            </a-radio>
                            <a-radio value="0">
                                否
                            </a-radio>
                        </a-radio-group>
                    </a-form-model-item>
                    <a-form-model-item v-if="form.isFinish == '1'" ref="isSatisfaction"
                        label="客户是否满意                                                                                                 "
                        required prop="isSatisfaction">
                        <a-radio-group v-model="form.isSatisfaction">
                            <a-radio value="1">
                                是
                            </a-radio>
                            <a-radio value="3">
                                否
                            </a-radio>
                        </a-radio-group>
                    </a-form-model-item>
                    <a-form-model-item v-if="form.isFinish == 1" ref="returnVisitText" label="其他反馈" prop="returnVisitText">
                        <a-textarea v-model="form.returnVisitText" style="height: 80px;" :maxLength="100" rows="3"
                            placeholder="请输入其他反馈，不超过100个字" auto-size />
                        <div style="text-align: right;position: absolute;width: 100%">
                            {{ form.returnVisitText.length }}/100
                        </div>
                    </a-form-model-item>
                </template>

            </a-form-model>
        </a-modal>
    </div>
</template>
<script>
import moment from 'moment';
import { IMG_API } from "@/config";
import * as  api from '@/api/demandPool';
export default {
    data() {
        return {
            IMG_API,
            visible: false,
            confirmLoading: false,
            needColumns: [],
            options: [],
            fieldNames: { label: 'classifyName', value: 'classifyId', children: 'childList' },
            form: {
                solutionTime: '',// 解决时间
                serverDescribe: '',// 回复用户内容
                demandServerType: undefined,// 需求服务类型
                firstsClassify: '',
                secondsClassify: '',
                thirdsClassify: '',
                solutionInformation: undefined,// 实际解决情况
                isReasonableDemand: '1',// 是否有效需求
                isGivePoints: 0,// 是否发放积分
                givePointsType: '1',// 积分发放类型
                givePointsAmount: '',// 发放数量
                isSatisfaction: '1',// 是否满意
                isResolved: '1',// 需求是否解决(1:已解决 0:未解决)'
                isFinish: '1',// 是否完成回访(0:未完成1:已完成)'
                requireCallback: '1',// 是否需要回访(1:需要0:不需要)
                returnVisitText: '',
                solutionDate:'',
                solutionH:'',
            },
            rules: {
                solutionTime: [{ required: true, message: '请选择需求解决时间', trigger:['blur','change'] },],
                Classify: [{ required: true, message: '请选择需求分类', trigger: 'blur' },],
                demandServerType: [{ required: true, message: '请选择需求类型', trigger: 'blur' },],
                serverDescribe: [{ required: true, message: '请填写服务描述', trigger: 'blur' },],
                linkmanPhone: [{ required: true, message: '请填写客户联系方式', trigger: 'blur' },],
                solutionInformation: [{ required: true, message: '请选择实际解决情况', trigger: 'blur' },],
                givePointsAmount: [{ required: true, message: '请填写积分发放数量', trigger: 'blur' },],
            },
            demandServiceTypeList: [],
            actualColumns: [{ name: '实际解决', key: '1' }, { name: '及时回复、响应', key: '2' }, { name: '受限', key: '3' }],
        }
    },
    props: {
        id: {
            type: String,
            default: ''
        },
        show: {
            type: Boolean,
            default: false
        },
        startingTime: {
            type: String,
            default: ''
        }
    },
    watch: {
        show(val) {
            this.visible = val;
        }
    },
    
    computed:{
        solutionTime(){
            if (this.form.solutionDate && this.form.solutionH) {
                this.form.solutionTime = moment(this.form.solutionDate).format("YYYY-MM-DD") +" " + moment(this.form.solutionH).format("HH:mm")
                this.$refs.ruleForm.validateField('solutionTime')
                if(moment(this.form.solutionTime).isSame(this.startingTime)){
                       return
                } 
                if(moment(this.form.solutionTime).isAfter(this.startingTime)){
                    console.log('结束时间大于开始时间');
                }else{
                    this.$message.error('您填写的需求解决时间早于需求提出时间，请确认后重新输入')
                    this.form.solutionTime = ''
                    this.form.solutionH = ""
                }
                if(moment(this.form.solutionTime).isAfter(moment())){
                    this.$message.error('您填写的需求解决时间晚于当前时间，请确认后重新输入')
                    this.form.solutionTime = ''
                    this.form.solutionH = ""
                }

            }else{
                this.form.solutionTime = ""
            }
        },
         // 解决时间是否可选择
         disabledchoiceSolutionTime(){
            if(this.form.solutionDate){
                return false
            }else{
                this.form.solutionH = ""
                return true
            }
        },
    },
    created() {
        console.log(this.startingTime);
        this.getServertype()
        this.getclassFiy()
    },
    methods: {
        recursion(res) {
            for (let index = 0; index < res.length; index++) {
                const item = res[index];
                if (item.childList.length) {
                    this.recursion(item.childList)
                } else {
                    item.childList = null
                }
            }
        },
        // 分类
        async getclassFiy() {
            const res = await api.getclassFiy();
            this.recursion(res.data)
            this.options = res.data;
        },
        //需求服务类型
        async getServertype() {
            const res = await api.requirementDemandServertype();
            if (res.code == 200) {
                this.demandServiceTypeList = res.data;
            }
        },
        // 限制时间
        disabledDate(current) {
            // 获取当前日期
            return current > moment().endOf('day') || current < moment(this.startingTime ? moment(this.startingTime).format("YYYY-MM-DD") : '');
        },
        disabledTime(current) {
            // 获取当前时间 
            const currentTime = moment();
            let str = this.startingTime
            if (!this.form.solutionTime) {
                if(moment(str).format("YYYY-MM-DD") == currentTime.format("YYYY-MM-DD")){
                    return {
                    disabledHours: () => {
                        let a = this.range(0, 24).splice(0, moment(str).hour())
                        let b = this.range(0, 24).splice(currentTime.hour() + 1)
                        return [...a, ...b]
                    },
                    disabledMinutes: () => {
                        if ( currentTime.hour() == moment(str).hour()) {
                            let a = this.range(0, 60).splice(0,moment(str).minute())
                            let b = this.range(0, 60).splice(currentTime.minute() + 1)
                            return [...a, ...b]
                        } else {
                            console.log('显示完整时就按')
                            return this.range(0, 60).splice(currentTime.minute() + 1)
                        }
                    },
                }
                }
                return {
                    disabledHours: () => this.range(0, 24).splice(currentTime.hour() + 1),
                    disabledMinutes: () => this.range(0, 60).splice(currentTime.minute() + 1),
                    disabledSeconds: () => this.range(0, 60).splice(currentTime.second() + 1),
                };
            }
            if (this.form.solutionTime.format("YYYY-MM-DD") === moment(str).format("YYYY-MM-DD") && moment(str).format("YYYY-MM-DD") == currentTime.format("YYYY-MM-DD")) {
                return {
                    disabledHours: () => {
                        let a = this.range(0, 24).splice(0, moment(str).hour())
                        let b = this.range(0, 24).splice(currentTime.hour() + 1)
                        return [...a, ...b]
                    },
                    disabledMinutes: () => {
                        console.log(this.form.solutionTime.hour(), currentTime.hour(), moment(str).hour())
                        if (this.form.solutionTime.hour() == currentTime.hour() && this.form.solutionTime.hour() == moment(str).hour()) {
                            console.log('开始时间 等于结束时间,结束时间等于当前时间=========》', moment(str).hour());
                            let a = this.range(0, 60).splice(currentTime.minute() + 1)
                            let b = this.range(0, 60).splice(currentTime.minute() + 1)
                            return [...a, ...b]
                        } else if (this.form.solutionTime.hour() == moment(str).hour() && this.form.solutionTime.hour() != currentTime.hour()) {
                            console.log('开始时间 等于结束时间,结束时间不等于当前时间=========》', moment(str).hour());
                            let a = this.range(0, 60).splice(0, moment(str).minute())
                            return a
                        } else if (this.form.solutionTime.hour() != moment(str).hour() && this.form.solutionTime.hour() == currentTime.hour()) {
                            console.log('开始时间 不等于结束时间,结束时间等于当前时间=========》', moment(str).hour());
                            return this.range(0, 60).splice(currentTime.minute() + 1)
                        } else {
                            console.log('显示完整时就按')
                            return []
                        }

                    },
                }
            } else if (this.form.solutionTime.format("YYYY-MM-DD") === moment(str).format("YYYY-MM-DD") && moment(str).format("YYYY-MM-DD") !== currentTime.format("YYYY-MM-DD")) {
                return {
                    disabledHours: () => {
                        let a = this.range(0, 24).splice(0, moment(str).hour())
                        return a
                    },
                    disabledMinutes: () => {
                        console.log(this.form.solutionTime.hour() ,currentTime.hour() ,moment(str).hour());
                        if(this.form.solutionTime.hour() == currentTime.hour() && this.form.solutionTime.hour() == moment(str).hour()){
                            let a = this.range(0, 60).splice(0, moment(str).minute())
                            let b = this.range(0, 60).splice(currentTime.minute() + 1)
                            return [...a,...b]
                        }else if(this.form.solutionTime.hour() == moment(str).hour() &&  this.form.solutionTime.hour()!== currentTime.hour()){
                           return this.range(0, 60).splice(0, moment(str).minute())
                        } else {
                            return []
                        }
                    },
                }
            }
            else if (this.form.solutionTime.format("YYYY-MM-DD") !== currentTime.format("YYYY-MM-DD")) {

            }
            else {
                return {
                    disabledHours: () => this.range(0, 24).splice(currentTime.hour() + 1),
                    disabledMinutes: () => this.range(0, 60).splice(currentTime.minute() + 1),
                    disabledSeconds: () => this.range(0, 60).splice(currentTime.second() + 1),
                };
            }
        },
        range(start, end) {
            const result = [];
            for (let i = start; i < end; i++) {
                result.push(i);
            }
            return result;
        },
        handleOk() {
            this.$refs.ruleForm.validate((valid) => {
                if (valid) {
                    let form = {};
                    // form = this.form
                    form.solutionTime = this.form.solutionTime ?this.form.solutionTime : '';
                    form.serverDescribe = this.form.serverDescribe
                    form.demandServerType = this.form.demandServerType
                    form.firstsClassify = this.form.firstsClassify
                    form.secondsClassify = this.form.secondsClassify
                    form.thirdsClassify = this.form.thirdsClassify
                    form.isReasonableDemand = this.form.isReasonableDemand
                    form.solutionInformation = this.form.solutionInformation
                    form.isGivePoints = this.form.isGivePoints
                    form.requireCallback = this.form.requireCallback
                    if (form.isGivePoints == 1) {
                        form.givePointsType = this.form.givePointsType
                        form.givePointsAmount = this.form.givePointsAmount
                    }
                    if (form.requireCallback == 1) {
                        form.isFinish = this.form.isFinish
                        form.returnVisitType = "2"
                        if (form.isFinish == 1) {
                            form.isResolved = this.form.isResolved
                            form.isSatisfaction = this.form.isSatisfaction
                            form.returnVisitText = this.form.returnVisitText
                        }
                    }
                    console.log(form);
                    api.serviceFinishWorkOrder({ ...form, workOrderId: this.id, }).then(res => {
                        console.log(res, '====');
                        if (res.code == "200") {
                            this.$message.success('提交成功');
                            this.handleCancel()
                            this.$emit('resetSearchForm')
                        } else {
                            this.$message.error(res.msg);
                        }
                    })
                }
            });
        },
        handleCancel() {
            this.$emit('update:show', false)
            Object.assign(this.form, this.$options.data().form)
        },
        onChange(value) {
            console.log(value);
            switch (value.length) {
                case 1:
                    this.form.firstsClassify = value[0]
                    break;
                case 2:
                    this.form.firstsClassify = value[0]
                    this.form.secondsClassify = value[1]
                    break;
                case 3:
                    this.form.firstsClassify = value[0]
                    this.form.secondsClassify = value[1]
                    this.form.thirdsClassify = value[2]
                    break;

                default:
                    break;
            }
        },
        openChange(){
            if(!this.form.solutionDate){
                this.form.solutionH =''
            }
        },
    }
}
</script>
<style scoped>
.ant-form-item {
    margin-bottom: 0;
}

h3 {
    margin-top: 20px;
}
</style>
  