<template>
    <div>
        <a-modal width="800px" :maskClosable="false"  title="服务审核"
            :visible="true" :confirm-loading="confirmLoading" @ok="handleOk" @cancel="handleCancel">
            <a-form-model ref="ruleForm" :rules="rules" :model="form">
                <h3  style="margin-top:0">审核信息</h3>
                <a-form-model-item ref="auditState" label="审核是否通过" required prop="auditState">
                    <a-radio-group v-model="form.auditState">
                        <a-radio :value="1">
                            是
                        </a-radio>
                        <a-radio :value="0">
                            否
                        </a-radio>
                    </a-radio-group>
                </a-form-model-item>
                <a-form-model-item v-if="form.auditState == '0'" ref="rejectCause" label="审核驳回原因"
                    prop="rejectCause">
                    <a-textarea style="height: 100px;" v-model="form.rejectCause" :maxLength="200" rows="3"
                        placeholder="请输入审核驳回原因，不超过200个字" auto-size />
                    <div style="text-align: right;position: absolute;width: 100%;">
                        {{ form.rejectCause.length }}/200
                    </div>
                </a-form-model-item>
            </a-form-model>
        </a-modal>
    </div>
</template>
<script>
import * as  api from '@/api/demandPool';
export default {
    props: {
        show: {
            type: Boolean,
            default: false
        },
        id: {
            type: String,
            default: ''
        },
    },
    watch: {
        show(val) {
            this.visible = val;
        }
    },
    data() {
        return {
            visible: false,
            confirmLoading: false,
            form: {
                rejectCause: '',// 审核驳回原因
                auditState: 1,// 审核是否通过
            },
            rules: {
                rejectCause: [{ required: true, message: '请填写审核驳回原因', trigger: 'blur' },],
            },
        };
    },
    created() {
    },
    methods: {
       
        handleOk(e) {
            this.$refs.ruleForm.validate((valid) => {
                if (valid) {
                    this.confirmLoading = true;
                    api.auditWorkorder({ ...this.form, workOrderId: this.id }).then(res => {
                        if (res.code == "200") {
                            this.$message.success("提交成功");
                            this.handleCancel()
                            this.$emit('resetSearchForm')
                        } else {
                            this.$message.error(res.msg);
                        }
                        this.confirmLoading = false
                    })
                }
            })

        },
        handleCancel(e) {
            this.$emit('update:show', false)
            this.confirmLoading = true;
            Object.assign(this.form, this.$options.data().form)
        },
    },
};
</script>
  