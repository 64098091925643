<template>
    <div>
        <a-modal :title="title" :maskClosable="false" :visible="true" @ok="handleOk" @cancel="handleCancel"
            :confirm-loading="confirmLoading">
            <a-form-model ref="ruleForm" :model="form">
                <a-form-model-item ref="checkedValue" :label="type == 1 ? '服务负责人' : '服务协作人'" required prop="checkedValue">
                    <a-tree-select :replaceFields="{
                        title: 'organizationName',
                        key: 'organizationId',
                        children: 'childrenList',
                        value: 'organizationId'
                    }" :filterTreeNode="false" show-search :dropdown-style="{ maxHeight: '300px', overflow: 'auto' }"
                        v-model="form.checkedValue" :maxTagCount="1" placeholder="请输入姓名或手机号以选择"
                        :getPopupContainer="(triggerNode) => triggerNode.parentNode" :tree-data="treeData"
                        @select="handleSelect" @search="search">
                    </a-tree-select>
                </a-form-model-item>
            </a-form-model>
        </a-modal>
    </div>
</template>
<script>
import * as  api from '@/api/demandPool';
export default {
    data() {
        return {
            confirmLoading: false,
            visible: false,
            form: {
                checkedValue: undefined
            },
            treeData: [],
            replaceFields: {},
            lableaname: ''
        }
    },
    props: {
        id: {
            type: String,
            default: ''
        },
        show: {
            type: Boolean,
            default: false
        },
        title: {
            type: String,
            default: ''
        },
        type: {

        }

    },
    watch: {
        show(val) {
            this.visible = val;
        }
    },
    async created() {
        this.search()
        if (this.type == 1) {
            this.assignWorkorder = ''
        } else {
            this.assignWorkorder = '服务协作人'
        }
    },
    methods: {
        handleOk() {
            console.log(this.form.checkedValue);
            this.$refs.ruleForm.validate(async (valid) => {
                if (valid) {
                    // 分派/重新分派负责人
                    this.confirmLoading = true
                    if (this.type == 1) {
                        api.assignWorkorder({
                            principalId: this.form.checkedValue,
                            workOrderId: this.id
                        }).then(res => {
                            this.confirmLoading = false
                            if (res.code == "200") {
                                this.$message.success('提交成功');
                                this.$emit('resetSearchForm')
                                this.handleCancel()
                            } else {
                                this.$message.error(res.msg);
                            }
                        })
                    } else {
                        const res = await api.addCooperation({
                            cooperationId: this.form.checkedValue,
                            workOrderId: this.id
                        });
                        this.confirmLoading = false
                        if (res.code == "200") {
                            this.$message.success('提交成功');
                            this.$emit('resetSearchForm')
                            this.handleCancel()
                        } else {
                            this.$message.error(res.msg);
                        }
                    }


                }
            });
        },
        handleCancel() {
            this.$emit('update:show', false)
            Object.assign(this.form, this.$options.data().form)
        },
        seta(aa) {
            for (let item = 0; item < aa.length; item++) {
                if (aa[item].childrenList && aa[item].childrenList.length > 0 && aa[item].demandUserList && !aa[item].demandUserList.length) {
                    this.seta(aa[item].childrenList);
                    aa[item].selectable = false
                } else if (aa[item].childrenList && aa[item].childrenList.length > 0 && aa[item].demandUserList && aa[item].demandUserList.length) {
                    console.log(aa[item]);
                    aa[item].selectable = false
                    if (aa[item].childrenList) {
                            this.seta(aa[item].childrenList);
                        }
                    for (let itt = 0; itt < aa[item].demandUserList.length; itt++) {
                        let a = aa[item].demandUserList[itt]
                        aa[item].childrenList.push({
                            organizationId: a.userId,
                            organizationName: a.userName,
                        })
                    };
                } else {
                    if (aa[item].demandUserList && aa[item].demandUserList.length) {
                        aa[item].selectable = false
                        for (let itt = 0; itt < aa[item].demandUserList.length; itt++) {
                            let a = aa[item].demandUserList[itt]
                            aa[item].childrenList.push({
                                organizationId: a.userId,
                                organizationName: a.userName,
                            })
                        }
                    }

                }
            }
        },

        async search(val) {
            let res = await api.getDemandUserTree({
                roleId: this.type == 1 ? 3 : 4,
                workOrderId: this.id,
                phoneNumberOrName: val
            })
            this.seta(res.data)
            console.log('res.data', res.data)
            this.treeData = res.data
        },
        handleSelect(val, node, extra) {
            console.log(val, node, extra);

        },


    }
}
</script>